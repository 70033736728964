const careerOptions = [
    {
        value: 0,
        label: "电子竞技员",
        price: [280, 380, 580,780,980] // 初中高级价格
    },
    {
        value: 1,
        label: "电子竞技运营师",
        price: [350, 450, 650,850,1050]
    },
    {
        value: 2,
        label: "电子竞技教练员",
        price: [350, 450, 650]
    },
    {
        value: 3,
        label: "电子竞技数据分析师",
        price: [300, 400, 600]
    },
    {
        value: 4,
        label: "电子竞技战术分析师",
        price: [300, 400, 600]
    },
    {
        value: 5,
        label: "电子竞技主播",
        price: [350, 450, 650]
    },
    {
        value: 6,
        label: "电子竞技解说",
        price: [350, 450, 650]
    }
    ,
    {
        value: 7,
        label: "电子竞技裁判员",
        price: [350, 450, 650]
    }
]
const skillOptions = [{
    label: 'MOBA',
    value: 0
}, {
    label: 'FPS',
    value: 1
}]

let levelOptions = [
	{
	    label: '初级',
	    value: 0
	},
	// {
	//     label: '中级',
	//     value: 1
	// },
	// {
	//     label: '高级',
	//     value: 2
	// },
]

let levelOptions1=[
    {
        label: '初级',
        value: 0
    },
    // {
    //     label: '中级',
    //     value: 1
    // },
    // {
    //     label: '高级',
    //     value: 2
    // },
]
let levelOptions2=[
    {
        label: '五级/初级',
        value: 0
    }
]

// let levelOptions2=[
//     {
//         label: '五级/初级',
//         value: 0
//     },
//     {
//         label: '四级/中级',
//         value: 1
//     },
//     {
//         label: '三级/高级',
//         value: 2
//     },
//     {
//         label: '二级/技师',
//         value: 3
//     },
//     {
//         label: '一级/高级技师',
//         value: 4
//     },
// ]

export default {
    data() {
        return {
            careerOptions, skillOptions,
            levelOptions,levelOptions1,levelOptions2
        }
    }
}
