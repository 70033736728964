import {getUserInfo, setUserInfo} from "@/utils/auth";

const state = {

}

const mutations = {

}

const actions = {

}

export default {
    state, mutations, actions
}
