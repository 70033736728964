<template>
  <el-dialog
      :visible.sync="isShowDialog"
      custom-class="loginDialog"
      title="提示"
      top="20vh"
      width="580px"
      @close="closeDialog"
  >
    <div slot="title" class="c-login-modal_bd">
      <i class="icon-modal_close" @click="closeDialog"></i>
      <p class="head-title">登录</p>
     
    </div>
	
	<div class="exam_tab">
		<span v-for="(item,index) in tabList" :key="item.id" :class="item.selected" @click="click_exam_tab(index)">{{item.name}}</span>
	</div>
	
	 <div class="sub-title" v-if="tabListindex==1">未注册的手机号验证通过后将自动注册并登录</div>
	 
    <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginFormRules"
        class="form"
        label-width="0"
        @submit.native.prevent
    >
      <el-form-item class="form-row-mobile" prop="mobile">
        <el-input v-model="loginForm.mobile" placeholder="输入手机号码"/>
      </el-form-item>
	  
	  
	  <el-form-item class="form-row-smsCode" prop="smsCode" v-show="tabListindex==0">
	    <el-input
	        v-model="loginForm.smsCode"
			type="password"
	        placeholder="输入密码"	        
	    />
	 
	  </el-form-item>
	  
      <el-form-item class="form-row-smsCode" prop="smsCode" v-show="tabListindex==1">
        <el-input
            v-model="loginForm.smsCode"
            placeholder="输入验证码"
            style="width:300px"
            @change="loginChange"
        />
        <el-button v-if="countdown==false" :disabled="sendCodeDisabled" class="send-btn" @click="sendSmsCode">获取验证码
        </el-button>
        <el-button v-else :disabled="sendCodeDisabled" class="send-btn" @click="sendSmsCode">{{
            countdown
          }} 
        </el-button>
      </el-form-item>
	  
	  
      <div class="protocol-options">
        <div class="checkbox-item">
          <el-checkbox v-model="loginForm.checked" size="medium" @change="checkChange"></el-checkbox>
          <span class="txt">登录即表示同意</span>
        </div>
        <a class="link" href="https://img.lianbadj.com/html/chinesa%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.html"
           target="_Blank">《注册协议》</a>
        <a class="link" href="https://img.lianbadj.com/html/chinesa%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.html"
           target="_Blank">《隐私协议》</a>
      </div>
      <el-form-item style="margin-bottom: 0">
        <div v-show="showErrTxt" class="err_Txt">你已获取验证码,验证码15分钟内有效.</div>
      </el-form-item>
      <el-form-item style="padding-bottom:40px">
        <el-button
            :disabled="disabled_login"
            :loading="loading"
            class="btn-button btn-primary btn-login"
            @click="login">登录
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {setToken, removeToken, setUserInfo, removeUserInfo, setTime} from "@/utils/auth";
import {sendSmsCode, register,login} from "@/api/login";
import eventBus from "@/components/component/eventBus";
import {Message} from "element-ui";
import {cookiegetToken,cookiesetToken,cookieremoveToken} from "@/utils/authCookies"  

export default {
  data() {
    let validateMobile = (rule, value, callback) => {
      if (rule.field == 'mobile') {
        if (/^1[2|3|4|5|6|7|8|9]\d{9}$/g.test(value)) {
          callback()
        } else {
          callback(new Error('请输入手机号'))
        }
      }
    }
    let validateCode = (rule, value, callback) => {
      
	  
	  if(this.tabListindex==1){
	  		  
	  		 if (rule.field == 'smsCode') {
	  		   if (value == '') {
	  		     this.disabled_login = true
	  		     callback(new Error('请输入验证码'));
	  		   } else {
	  		     this.disabled_login = false
	  		     callback()
	  		   }
	  		 }
	  		  
	  }else{
	  		  if (value.length>=6) {
				  this.disabled_login = false
	  		    callback()
	  		  } else {
				  this.disabled_login = true
	  		    callback(new Error('请输入至少6位密码'))
	  		  }
	  }
	  
    }
    return {
      isShowDialog: false, // 是否显示登录弹窗
      loginForm: {mobile: "", smsCode: "",channelsharingcode:"", checked: true,type:1},
      loginFormRules: {
        mobile: [{required: true, trigger: "change", validator: validateMobile}],
        smsCode: [{required: true, trigger: "change", validator: validateCode}]
      },
      disabled_login: true,
      loading: false,
      sendCodeDisabled: false,
      countdown: false, // 倒计时,false不显示读秒数
      showErrTxt: false,
      isShowAndClose: false,
	  tabList: [{name:"密码登录",selected:"selected"},{name:"短信登录/注册",selected:""}],
	  tabListindex:0,
    }
  },
  created() {
    eventBus.$emit('hideLoginInstance')
  },
  methods: {
	  click_exam_tab(index){
	  		  this.tabListindex=index
	  		  if(index==1){
	  			  this.tabList[0]['selected']=""
	  			  this.tabList[1]['selected']="selected"
	  			  this.loginForm.smsCode=""
	  		  }else{
	  			  this.tabList[0]['selected']="selected"
	  			  this.tabList[1]['selected']=""
				  this.loginForm.smsCode=""
	  		  }
	  },
    loginChange(value, e) {
      if (value === '') return
      this.login()
    },
    login() {
      this.loading = true
    
   
      let channelsharingcode = cookiegetToken("code")
      console.log("channelsharingcode=",channelsharingcode)
      this.loginForm.channelsharingcode = channelsharingcode
console.log("loginForm=",this.loginForm)
	  if(this.tabListindex==1){
	  
      register(this.loginForm).then(res => {
        if (/成功/.test(res.msg)) {
          this.$message({
            message: '登录成功',
            type: 'success'
          })
          this.isShowDialog = false
          setUserInfo(Object.assign(res.data, {mobile: this.loginForm.mobile}))
          setToken(res.data.token)
          // console.log(res.data)
		  setTimeout(()=>{
			  window.location.reload()
		  }, 1000)
        } else {
          this.$message({
            message: '登录失败,请检查手机号或验证码是否输入正确.',
            type: 'warning'
          })
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.$message({
          message: '登录失败,请稍后重试.',
          type: 'error'
        })
        this.loading = false
      })
	  
	  }else{
	  const params={
	  				  userName:this.loginForm.mobile,
              password:this.loginForm.smsCode
	  			  }
	  
	  login(params).then(res => {
	  			
	  			 this.loading = false
	  			  if(res.code==0){
	  				  this.$message({
	  				    message: '登录成功',
	  				    type: 'success'
	  				  })
	  				  this.isShowDialog = false
	  				  setUserInfo(Object.assign(res.data, {mobile: this.loginForm.mobile}))
	  				  setToken(res.data.token)
	  				  // console.log(res.data)
	  				  setTimeout(()=>{
	  				  			  window.location.reload()
	  				  }, 1000)
	  				  
	  			  }else{
	  				 
					  this.$message({
					    message: '错误提示：'+res.msg,
					    type: 'warning'
					  })
	  			  }
	     
	  }).catch(err => {
	  
	   this.$message({
	     message: '登录失败,请稍后重试.',
	     type: 'error'
	   })
	   this.loading = false
	  })
	  }
	  
    },
    // 发送验证码
    sendSmsCode() {
      this.$refs['loginForm'].validateField('mobile', valid => {
        if (!valid == '') return
        // let time = new Date().getTime()
        // if (parseInt(time / 60000) - parseInt(Number(getTime()) / 60000) <= 15) {
        //   this.showErrTxt = true
        //   return
        // } else {
        //   this.showErrTxt = false
        // }
        this.sendCodeDisabled = true
		let url=window.location.href,code
		if(/code/.test(url)){
			code=url.split('?')[1].split('=')[1]
    }
    
        sendSmsCode({mobile: this.loginForm.mobile,code:code}).then(res => {
          if (/成功/.test(res.msg)) {
            this.$message({
              message: '已发送验证码,请注意查收.',
              type: 'success'
            })
            this.countdown = 59
            setTime()
            this.mySetInterval()
          }
        }).catch(err => {
          this.$message({
            message: '验证码发送失败,请稍后重试',
            type: 'error'
          })
		  this.sendCodeDisabled = false
        })
      })
    },
    // 计时器
    mySetInterval() {
      let flag = setInterval(() => {
        this.countdown--
        if (this.countdown == 0) {
          clearInterval(flag)
          this.countdown = false
          this.sendCodeDisabled = false
        }
      }, 1000)
    },
    loginOut() {
      return new Promise((resolve, reject) => {
        removeUserInfo()
        removeToken()
        this.$message({
          message: '退出成功',
          type: 'success'
        })
        window.location.reload()
        this.$router.push('/index')
        resolve(true)
      })
    },
    // 显示登录界面
    showDialog() {
      this.isShowDialog = true
    },
    // 关闭登录界面
    closeDialog() {
      this.isShowDialog = false
      this.loginForm = {mobile: "", smsCode: "",channelsharingcode:"", checked: true}
      if (this.isShowAndClose) window.location.reload()
    },
    // 登录过期需要重新登录,取消登录后重新刷新页面
    showAndClose() {
      this.isShowAndClose = true
    },
    checkChange(value) {
      this.disabled_login = !value
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(.loginDialog) {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;

    .form {
      margin: 0 60px;

      .form-row-mobile {
        margin-bottom: 40px;

        .el-form-item__content {
          margin-left: 0px;
          line-height: 40px;
          position: relative;
          font-size: 14px;

          .el-input {
            .el-input-group__prepend {
              padding: 0;
            }
          }

          .el-input__inner {
            background: #fff no-repeat 10px 50%;
            background-image: url("@/static/phone.png");
            background-size: 30px 30px;
            border-color: #ccc;
            border-radius: 2px;
            height: 58px;
            color: #333;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            display: inline-block;
            font-size: 16px;
            line-height: 40px;
            outline: 0;
            padding: 0 15px;
            padding-left: 52px;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;
          }
        }
      }

      .form-row-smsCode {
        margin-bottom: 20px;

        .el-form-item__content {
          .el-input {
            float: left;

            .el-input__inner {
              background: #fff no-repeat 10px 50%;
              background-image: url("@/static/code.png");
              background-size: 30px 30px;
              border-color: #ccc;
              border-radius: 2px;
              height: 58px;
              color: #333;
              border: 1px solid #dcdfe6;
              box-sizing: border-box;
              display: inline-block;
              font-size: 16px;
              line-height: 40px;
              outline: 0;
              padding: 0 15px 0 52px;
              transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              width: 100%;
            }
          }
        }

        .send-btn {
          float: right;
          cursor: pointer;
          transition-duration: 0.1s;
          width: 140px;
          height: 58px;
          background: #fff;
          border-radius: 2px;
          border: 1px solid #3960f4;
          font-size: 16px;
          font-weight: 400;
          color: #3960f4;
          text-align: center;
        }

        .send-btn.is-disabled {
          color: #C0C4CC;
          cursor: not-allowed;
          background-image: none;
          background-color: #FFF;
          border-color: #EBEEF5;
        }
      }

      .protocol-options {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        padding-top: 10px;
        margin-bottom: 40px;
        color: #666;
        display: flex;
        -webkit-box-align: center;
        align-items: center;

        .checkbox-item {
          .el-checkbox__inner {
            width: 20px;
            height: 20px;
          }

          .el-checkbox__inner::after {
            top: 2px;
            left: 7px;
            height: 10px;
          }

          .txt {
            margin: 0 6px 0 10px;
          }
        }

        .link {
          color: #3960f4;
        }
      }

      .err_Txt {
        text-align: left;
        z-index: 2;
        color: #f93d3d;
        font-size: 14px;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .el-form-item__error {
        font-size: 14px;
      }
    }
  }

  .c-login-modal_bd {
    position: relative;

    .mobile {
      font-size: 16px;
    }

    .icon-modal_close {
      position: absolute;
      cursor: pointer;
      z-index: 2;
      right: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background-size: 100% 100%;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url("@/static/close_dialog.png");
      transition-duration: 0.1s;
    }

    .head-title {
      font-size: 30px;
      font-weight: 600;
      color: #333;
      text-align: center;
      padding-top: 55px;
    }

    .sub-title {
      padding-top: 15px;
      padding-bottom: 35px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      text-align: center;
    }
  }
}
.exam_tab{
		display: flex;
		justify-content:space-between;
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.exam_tab span{
		padding: 10px 0px;
		flex: 1;
		text-align: center;
		
		background-color: #eeeeee;
	}
	
	.exam_tab span.selected{
		background-color: blue;
		color:white;
	}
	
	.sub-title{
		text-align: center;
		margin-bottom: 10px;
	}
</style>
