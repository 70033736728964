import Cookies from 'js-cookies'
 
// 设置 token 存储的 key
const CookieTokenKeyPre = 'Chinesa-User-Token-'
 
// 获取 token
export function cookiegetToken(token) {
  return Cookies.getItem(CookieTokenKeyPre+token)
}
 
// 设置 token
export function cookiesetToken(token,val,time) {
  return Cookies.setItem(CookieTokenKeyPre+token,val,time)
}
 
// 移除 token
export function cookieremoveToken(token) {
  return Cookies.removeItem(CookieTokenKeyPre+token)
}