<template>
  <el-dialog
      :visible.sync="show"
      class="HomeDialog"
      top="30vh"
      width="680px"
      @close="close"
  >
    <div class="c-login-modal_bd">
      <i class="icon-modal_close" @click="close"></i>
      <div class="tit">实名认证</div>
    </div>
    <div class="el-message-box__content">
      <p>根据中国通信工业协会电子竞技分会《电子竞技职业技能认定标准》规范，《电子竞技职业技能认定考试》需进行实名认证，实名认证审核通过才可进行职业技能认定的报名和考试。</p>
    </div>
    <div class="btn_box">
      <el-button class="btn-button btn-primary" @click="goUserInfo">前往实名认证</el-button>
    </div>
  </el-dialog>
</template>

<script>
import EventBus from "@/components/component/eventBus";

export default {
  data() {
    return {
      show: false,
    }
  },
  created() {
    EventBus.$on('showHomeDialog', () => {
      this.open()
    })
  },
  methods: {
    goUserInfo() {
      this.$router.push('/user/2')
      this.$store.commit('setUserBtnActiveNum', 2)
      this.close()
    },
    close() {
      this.show = false
    },
    open() {
      this.show = true
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog_tit {
  font-size: 24px;
  color: #101010;
}


.HomeDialog ::v-deep {
  .el-dialog {
    .el-dialog__header, .el-dialog__body {
      margin: 0;
      padding: 0;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      .c-login-modal_bd {
        position: relative;
        padding-top: 30px;

        .tit {
          height: 45px;
          margin-bottom: 35px;
          padding-top: 20px;
          font-size: 30px;
          font-weight: bold;
          color: #333;
          text-align: center;
        }

        .icon-modal_close {
          position: absolute;
          cursor: pointer;
          z-index: 2;
          right: 0;
          top: 0;
          width: 50px;
          height: 50px;
          background-size: 100% 100%;
          background-position: 50%;
          background-repeat: no-repeat;
          background-image: url("@/static/close_dialog.png");
          transition-duration: 0.1s;
        }
      }

      .el-message-box__content {
        padding: 20px 110px 40px;

        p {
          font-size: 14px;
          color: #333;
          line-height: 24px;
        }
      }

      .btn_box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;

        .el-button {
          width: 300px
        }
      }
    }
  }
}
</style>
