import {idCardGet} from '@/api/alipay'
import {getUserInfo, setUserInfo} from "@/utils/auth";

const state = {
    userBtnActiveNum: 0,
    certificateInfo: {}
}

const mutations = {
    setUserBtnActiveNum: (state, num) => {
        state.userBtnActiveNum = num
    },
    setCertificateInfo: (state, info) => {
        state.certificateInfo = info
    }
}

const actions = {
    getUserIsIndividual(state, params) {
        return new Promise((resolve, reject) => {
            idCardGet({adminUserId: getUserInfo().adminUserId}).then(res => {
                if (!(res.data == {})) {
                    setUserInfo(Object.assign(getUserInfo(), {
                        idCard: res.data.idCard,
                        userName: res.data.name,
                        state: res.data.state,
                        refuseReason: res.data.refuseReason,
                        id: res.data.id
                    }))
                    resolve(res.data.state)
                } else {
                    setUserInfo(Object.assign(getUserInfo(), {state: -1, refuseReason: ''}))
                    resolve(-1)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
}

export default {
    state, mutations, actions
}
