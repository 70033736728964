<template>
  <el-dialog
      :destroy-on-close="true"
      :visible.sync="showDailog"
      center
      custom-class="SignUpDialog"
      width="680px"
      @close="close">
    <div class="header">
      <i class="icon-modal_close" @click="close"></i>
      <div class="header_tit">填写报名信息{{list}}</div>
      <div class="header_txt">选择你想报考的职业、认定的技能及对应的技能证书等级</div>
    </div>s
    <div class="my_form">
      <el-form ref="form" :model="form" :rules="rules" label-width="200px" @submit.native.prevent>
        <el-form-item class="txt" label="考生姓名:">
          <p>{{ userName() }}（证件持有人）</p>
        </el-form-item>
        <el-form-item class="txt" label="证件号码:">
          <p>{{ idCard() }}</p>
        </el-form-item>
        <el-form-item label="报考职业:" prop="occupation">
          <el-select v-model="form.occupation" @change="occupationSelectChange" placeholder="请选择报考职业">
            <el-option
                v-for="item in careerOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.occupation!=1&&form.occupation!=5&&form.occupation!=6&&form.occupation!==''" label="认证技能:" prop="skill">
          <el-select v-model="form.skill" :disabled="levelDisabled" class="slt" placeholder="请选择认证技能">
            <el-option
                v-for="item in skillOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书等级:" prop="level">
          <el-select v-model="form.level" placeholder="请选择证书等级">
            <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="txt" label="报考内容:">
          <div class="SignUpTxt">
            <p>3次《电子竞技职业技能认定》理论考试机会</p>
            <p class="mt12">2次《电子竞技职业技能认定》实操考试机会</p>
          </div>
        </el-form-item>
        <el-form-item class="txt" label="报考内容:">
          <span>{{ price }}元</span>
        </el-form-item>
        <div class="ft">		
          <el-button class="btn btn-primary" type="primary" :disabled="onSubmitdisabled" :loading="onSubmitloading" @click="onSubmit">报名</el-button>
          <div class="err_txt">{{err_txt}}</div>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import mixin from './mixin'
import {kaoshiSign,levellist} from "@/api/login";
import eventBus from "@/components/component/eventBus";
import {getUserInfo} from '@/utils/auth'

export default {
  // 子组件接受传过来的值
  name: 'SignUp',
  props:['list'],
  watch:{
list(o,n){
  console.log(o);
  console.log(n);
}
  },
  data() {
    
    let validateOccupation = (rule, value, callback) => {
      if (rule.field == 'occupation') {
        // 报考职业为电子竞技运营师时,则认证技能不进行校验,下拉框不可选
        if (value == 1) {
          this.levelDisabled = true
          this.$refs['form'].clearValidate('skill')
        } else {
          this.levelDisabled = false
        }
        if (value === '') callback(new Error('请选择报考职业'))
        else callback()
        
      }
    }
    let validateSkill = (rule, value, callback) => {
      if (rule.field == 'skill') {
        if (this.form.occupation == 1) {
          callback()
        } else if (this.form.occupation != 1 & value === '') {
          callback(new Error('请选择认证技能'))
        } else {
          callback()
        }
      }
    }
    let validateLevel = (rule, value, callback) => {
      if (rule.field == 'level') {
        if (value === '') callback('请选择证书等级')
        else callback()
        this.setPrice()
      }
    }
    return {
      showDailog: false,
	  onSubmitloading:false,
	  onSubmitdisabled:false,
      // 用来判断子组件里面报考信息的值
       val_tetx:'111',
	   err_txt:"",
      form: {occupation: '', skill: '', level: '', amount: ''},
      levelDisabled: false, // 当职业为电子竞技运营师时,则认证技能下拉框不可选
      rules: {
        occupation: [
          {required: true, trigger: "change", validator: validateOccupation}
        ],
        skill: [
          {required: true, trigger: "change", validator: validateSkill}
        ],
        level: [
          {required: true, trigger: "change", validator: validateLevel}
        ]
      },
      price: '*', // 价格
    }
  },
  mixins: [mixin],
  created() {
    eventBus.$on('hideLoginInstance', () => {
      this.close()
    })
    
  },
  mounted() {
    // 将父组件的值存到本地
    this.val_tetx=this.myMessage
  },
  methods: {
	  
	getlevellist(){
		let that = this
		levellist().then(res=>{
		
				if(res.code==0){
					const levellistData = res.data
					this.levelOptions1 = levellistData.levellist
					this.levelOptions2 = levellistData.levellist2
					
					
					this.occupationSelectChange(0)
					
				}
			
		})
	},  
    // 测试方法
    aaa(k){
      this.val_tetx=k
    },
    occupationSelectChange(val) {
		
		this.form.skill = ''
		this.form.level = ''
		this.form.amount = ''
		this.price=''
		this.err_txt=''
		 
		  // console.log('occupationSelectChange',this.levelOptions1);
		  //  console.log('occupationSelectChange',this.levelOptions2);
		  
      if (this.form.occupation === 0 || this.form.occupation === 1) {
        this.levelOptions = [...this.levelOptions2]
      } else {
        this.levelOptions = [...this.levelOptions1]
      }
	  
    },
    userName() {
      return getUserInfo().userName
    },
    idCard() {
      return getUserInfo().idCard.replace(/^(\d{2})\d{13}(\d{3})$/, '$1*************$2')
    },
    setPrice() {
      if (this.form.occupation === '' || this.form.level === '') return
      this.price = this.careerOptions[this.form.occupation].price[this.form.level]
      //this.price = 0.01
    },
    // 报名
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
		// if(this.form.occupation==5 || this.form.occupation==6){
		// 	return
		// }
		
		this.onSubmitloading=true
		this.onSubmitdisabled=true
		
        this.sign()
      })
    },
    sign() {
      this.form = Object.assign(this.form, {amount: this.price})
      kaoshiSign(this.form).then(res => {
		 
		  if(res.code==0){
			 
			const div = document.createElement('div') // 创建div
			div.innerHTML = res.data// 将返回的form 放入div
			document.body.appendChild(div)
			document.forms[1].submit()
			setTimeout(() => {
			  // div.remove()
			}, 2000)
		
		}else{
					
			this.onSubmitloading=false
			this.onSubmitdisabled=false
			
			this.err_txt=res.msg
			setTimeout(function(){
				this.err_txt=""
			},3000);
		}
		
      }).catch(err => {
		  
		  this.onSubmitloading=false
		  this.onSubmitdisabled=false
        
      })
    },
    show() {
      
      this.showDailog = true
      this.getlevellist()
      
    },

    close(done) {
      console.log('close',done);
      this.showDailog = false
      this.reset()
    },
    reset() {
      this.form = {adminUserId: getUserInfo().adminUserId, occupation: '', skill: '', level: '', amount: ''}
      this.price = '*'
    },
    signUp() {
      console.log('signUp')
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(.SignUpDialog) {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #333;
    word-break: break-all;

    .header {
      .icon-modal_close {
        position: absolute;
        cursor: pointer;
        z-index: 2;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background-size: 100% 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-image: url("@/assets/close.png");
        transition-duration: .1s;
      }

      .header_tit {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        line-height: 60px;
        padding-top: 40px;
      }

      .header_txt {
        text-align: center;
        font-size: 14px;
        color: #999;
        padding-bottom: 40px;
      }
    }

    .my_form {
      .el-form-item {
        .el-form-item__label {
          font-size: 16px;
          color: #999;
        }

        .el-form-item__content {
          display: flex;
          width: 412px;

          .el-select {
            flex: 1;
          }
        }

        .SignUpTxt {
          .mt12 {
            margin-top: 12px;
          }
        }
      }

      .txt.el-form-item {
        .el-form-item__label, .el-form-item__content {
          line-height: 1.2;
          font-size: 16px;
        }
      }

      .ft {
        padding-top: 18px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .btn {
          margin: 0 auto;
          width: 300px;
          height: 44px;
          font-size: 16px;
        }

        .err_txt {
          min-height: 40px;
          padding-top: 10px;
          font-size: 14px;
          text-align: center;
          color: #f93d3d;
        }
      }
    }
  }
}
</style>
