const TokenKey = 'DZJJTOKEN'
const Time = 'Time'
const UserInfo = 'dzjjUserInfo'
let Base64 = require('js-base64').Base64

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

export function setTime() {
    let time = new Date().getTime()
    return localStorage.setItem(Time, time)
}

export function getTime() {
    return localStorage.getItem(Time)
}

export function setUserInfo(userInfo) {
    let obj = {
        userName: Base64.encode(userInfo.userName),
        mobile: Base64.encode(userInfo.mobile),
        adminUserId: Base64.encode(userInfo.adminUserId),
        idCard: Base64.encode(userInfo.idCard),
        type: userInfo.type, // 身份 0:管理员 1:普通用户
        state: userInfo.state, // 实名认证  0:未进行实名认证,1:已通过实名认证,2:未通过实名认证
        refuseReason: userInfo.refuseReason
    }
    return localStorage.setItem(UserInfo, JSON.stringify(obj))
}

export function getUserInfo() {
    let info = JSON.parse(localStorage.getItem(UserInfo))
    if (!info) return null
    let obj = {
        userName: Base64.decode(info.userName),
        mobile: Base64.decode(info.mobile),
        adminUserId: Base64.decode(info.adminUserId),
        idCard: Base64.decode(info.idCard),
        type: info.type,
        state: info.state,
        refuseReason: info.refuseReason
    }
    return obj
}

export function removeUserInfo() {
    return localStorage.removeItem(UserInfo)
}
