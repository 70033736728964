import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
import Layout from '@/layout'

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/index',
        children: [{
            path: '/index',
            component: () => import('@/view/home/index'),
            name: '首页',
        }]
    },
    {
        path: '/gamePlay/:page',
        component: Layout,
        redirect: '/gamePlay',
        children: [{
            path: '',
            component: () => import('@/view/gamePlay/index'),
            // alias: ['/0', '/1', '/2', '/3', '/4', '/5'],
            name: '电子竞技职业技能认定平台',
        }]
    },
    {
        path: '/search',
        component: Layout,
        redirect: '/search',
        children: [{
            path: '',
            component: () => import('@/view/search/index'),
            name: '证书查询',
        }]
    },
    {
        path: '/news',
        name: 'news',
        component: Layout,
        redirect: '/news',
        children: [{
            path: '',
            component: () => import('@/view/news/index'),
            name: '资讯动态',
        }]
    },
    {
        path: '/product',
        name: 'product',
        component: Layout,
        redirect: '/product',
        children: [{
            path: '',
            component: () => import('@/view/product/index'),
            name: '产品动态',
        }]
    },
    {
        path: '/user/:page',
        component: Layout,
        redirect: '/user',
        children: [{
            path: '/user/:page',
            component: () => import('@/view/user/index'),
            name: '用户信息',
        }]
    },
    {
        path: '/certificate',
        name: 'certificate',
        component: Layout,
        redirect: '/certificate',
        children: [{
            path: '',
            component: () => import('@/view/certificate/index'),
            name: '证书详情',
        }]
    },
    {
        path: '/zxdt/:page',
        name: 'zxdt',
        component: Layout,
        redirect: '/zxdt/:page',
        children: [{
            path: '',
            component: () => import('@/view/zxdt/index'),
            name: '新闻动态',
        }]
    },
	{
	    path: '/jiaoyu/:page',
	    name: 'jiaoyu',
	    component: Layout,
	    redirect: '/jiaoyu',
	    children: [{
	        path: '/jiaoyu/:page',
	        component: () => import('@/view/jiaoyu/index'),
	        name: '培训就业',
	    }]
	},
    {
	    path: '/mall/:page',
	    name: 'mall',
	    component: Layout,
	    redirect: '/mall',
	    children: [{
	        path: '/mall/:page',
	        component: () => import('@/view/mall/index'),
	        name: '商城主页',
	    }]
	},
    {
        path: '/showDo',
        name: 'showDo',
        component: Layout,
        redirect: '/showDo',
        children: [{
            path: '',
            component: () => import('@/view/showDo/index'),
            name: '联系我们',
        }]
    },
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
        y: 0
    }),
    routes: routes
})
const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

// const router = new VueRouter({
//     routes
// })


export default router
