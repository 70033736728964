<template>
  <div class="app-warrper">
    <headerBox/>
    <div class="content">
      <keep-alive>
        <router-view :key="$route.path"/>
      </keep-alive>
    </div>
    <footerBox/>
  </div>
</template>

<script>
import headerBox from "@/layout/components/header";
import footerBox from '@/layout/components/footer.vue'

export default {
  name: "index",
  components: {headerBox, footerBox},
  data() {
    return {};
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang='scss'>
@import '@/style/index.css';

//.popMenu {
//  background: #fff;
//  text-align: center;
//  border-radius: 4px;
//
//  ul {
//    width: 160px;
//    // min-width: 140px;
//    background-color: #fff !important;
//
//    .el-menu-item {
//      background-color: #fff !important;
//      line-height: 30px !important;
//      height: 40px !important;
//      color: #333 !important;
//    }
//  }
//}
//
//.popMenu:last-child {
//  ul {
//    width: 140px;
//    min-width: 140px;
//  }
//}

</style>
