import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/icons/iconfont/iconfont.css'
Vue.use(ElementUI)

import {SignUp, Login} from "@/components/index";

Vue.prototype.__SignUp = SignUp.install;
Vue.prototype.__Login = Login.install

Vue.config.productionTip = false



import router from './router'
import store from '@/store/index'

import {getToken} from "@/utils/auth";
import Cookies from 'js-cookies'
import {cookiegetToken,cookiesetToken,cookieremoveToken} from "@/utils/authCookies"  
let url=window.location.href,code
  if(/code=/.test(url)){
  code=url.split('?')[1].split('&')[0]
  let code1=code.split('=')[0]
  let code2=code.split('=')[1]
  console.log('code1',code1)
  console.log('code2',code2)

  if(code1=='code'){
  cookiesetToken("code",code2,28800);
  }
}
router.beforeEach((to, from, next) => {
    document.title = '电子竞技职业技能认定平台'
    // if (getToken() == null || getToken() == undefined) {
    //     if (to.path === '/index') {
    //         next()
    //     } else {
    //         next({path: '/index'})
    //     }
    // } else {
    //     next()
    // }
	
	 
		
    next()
})

router.afterEach((to, from, next) => {
	
	 
	 document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
	 let hm = document.createElement('script');
	 hm.src = 'https://hm.baidu.com/hm.js?db793f2b4c9bca88ccbcde32dd6b5eda';
	 hm.id = 'baidu_tj'
	 let src = document.getElementsByTagName('script')[0];
	 src.parentNode.insertBefore(hm, src);
	
})


new Vue({
    el: '#app',
    router, store,
    render: h => h(App)
})
