import Vue from "vue";
// 引入组件
import SignUp from './component/signUpDialog'
import Login from './component/login'

// 创建构造器
const SignUpBox = Vue.extend(SignUp);
const LoginBox = Vue.extend(Login)

SignUp.install = function (data) {
    // data：调用组件时传入参数【操作组件data中数据】
    // console.log('报名:', data);

    // 创建组件对象，并挂载
    let instance = new SignUpBox({
        data,
        created() {
            this.form.occupation = data.occupation
        }
    }).$mount() // $mount(document.createElement('div') 【也可以挂载到某个元素上】

    // 将组件添加到dom中
    document.body.appendChild(instance.$el);

    // 调用方法或操作属性
    Vue.nextTick(() => {
        if (data.isShow) instance.show()
        if (data.isReset) instance.reset()
        if (data.isClose) {
            instance.close()
        }
    });
};

Login.install = function (data) {
    // data：调用组件时传入参数【操作组件data中数据】
    console.log('登录:', data);

    // 创建组件对象，并挂载
    let instance = new LoginBox({
        data
    }).$mount() // $mount(document.createElement('div') 【也可以挂载到某个元素上】

    // 将组件添加到dom中
    document.body.appendChild(instance.$el);

    // 调用方法或操作属性
    Vue.nextTick(() => {
        if (data.isShow) instance.isShowDialog = data.isShow
        if (data.logOut) instance.loginOut()
        if (data.isShowAndClose) instance.showAndClose()
    });
};

export {
    SignUp, Login
}
// export default {
//     SignUp,
// }
