<template>
  <div class="footer">
    <div class="g-container bd">
      <div class="copyright">
        <div class="t1">
          <span>主办单位：</span>
          <a href="http://chinaesa.org.cn/">中国通信工业协会电子竞技分会</a>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a href="http://www.ccia.org.cn/">中国通信工业协会</a>
          <span style="margin-left: 20px;">支撑单位：</span>
          <a href="http://www.lianbadj.com">奥加电竞 霸夫电竞</a>
        </div>
        <div class="t2">
          <span>©2022 湖南奥加电竞文化发展有限公司</span>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span><a class="link" target="_blank" href="https://beian.miit.gov.cn">湘ICP备2022014795号-3</a></span>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a class="link" href="https://beian.miit.gov.cn/#/Integrated/index">湘公网安备 43011202000847号</a>
        </div>
      </div>
      <div class="service">
       <el-popover
            title="添加客服微信了解详情"
            placement="top"
            trigger="click"
            width="200px">
          <div style="width: 160px;height: 160px">
            <img alt="" src="/img/qq.jpg" style="width: 100%;height: 100%"/>
          </div>
		  <!-- <div style="width: 160px;height: 160px;margin-top:6px;">
		    <img alt="" src="../../assets/考试官网二维码/kefu02.jpg" style="width: 100%;height: 100%"/>
		  </div> -->
          <el-button slot="reference" class="btn-button btn-primary" type="primary" @click="click">在线咨询</el-button>
        </el-popover>
        <p class="t2">（服务时间：工作日 10:00-17:00）</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	data(){
		return {
			qqTabs:0
		}
	},
  methods: {
    click() {
      // window.location.href = 'https://104236.kefu.easemob.com/webim/im.html?configId=ebc819d6-e37a-4829-bcff-ec0924121c78'
    },
	qqTabsClick(item){
		console.log(item)
	},
  }
}
</script>


<style lang="scss" scoped>
.footer {
  width: 100%;
  padding: 40px 0 20px;
  background: #080f2b;
  color: #fff;
  font-size: 12px;
  line-height: 1.8;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  //position: absolute;
  bottom: 0;

  .g-container {
    width: 1080px;
    margin: 0 auto;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;

    .copyright {
      span,
      a {
        color: #fff;
      }

      a:hover {
        color: #3a8ee6;
        text-decoration: underline;
      }

      .t1,
      .t2 {
        line-height: 49px;
        opacity: 0.5;
      }

      .t2 {
        .link {
          background: url("@/static/b.png") no-repeat 0px 1px;
          background-size: 10px 10px;
          padding-left: 14px;
        }
      }
    }

    .service {
      .t2 {
        line-height: 49px;
        opacity: 0.5;
        text-align: center;
      }

      .btn-button {
        position: relative;
        overflow: hidden;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
      }

      .btn-primary {
        width: 185px;
        height: 49px;
        box-shadow: 0 4px 10px 0 rgba(14, 25, 47, 0.1);
        color: #fff;
        background: linear-gradient(90deg, #5b96ff, #3960f4) !important;
        border: none;
        cursor: pointer;
        outline: none;
        -webkit-appearance: none;
      }
    }
  }
}
</style>
