import request from '@/utils/request'

// 支付宝查询支付状态回调
export function alipayCallback(data) {
    return request({
        url: '/api/kaoshi/pay/alipayCallback',
        method: 'post',
        params: data
    })
}

// 获取用户实名认证状态
export function idCardGet(data) {
    return request({
        url: '/api/kaoshi/idCard/get',
        method: 'get',
        params: data
    })
}
