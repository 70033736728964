import request from '@/utils/request'

// 注册
export function register(data) {
    return request({
        url: '/api/kaoshi/register',
        method: 'post',
        params: data
    })
}

// 登录
export function login(data) {
    return request({
        url: '/api/kaoshi/login',
        method: 'post',
        params: data
    })
}



// 发送验证码
export function sendSmsCode(data) {
    return request({
        url: '/api/kaoshi/sendSmsCode',
        method: 'post',
        params: data
    })
}

// 报名
export function kaoshiSign(data) {
    return request({
        url: '/api/kaoshi/sign',
        method: 'post',
        params: data
    })
}


export function levellist(data) {
    return request({
        url: '/api/kaoshi/examcateprice/levellist',
        method: 'get',
        params: data
    })
}