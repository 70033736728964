<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  // components: { home }
  mounted() {
    let app = document.querySelector('#app')
    let time = false
    window.onresize = () => {
      if (time) return
      time = true
      var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      app.style.height = h + 'px'
      app.style.width = w + 'px'
      setTimeout(() => {
        time = false
      }, 300)
    }
  }
}
;
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /* margin-top: 60px; */
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

dt {
  display: block;
}

dd {
  display: block;
  margin-inline-start: 40px;
}
</style>
